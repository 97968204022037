import React from "react"
import {Link} from "gatsby"
import Profile from "../components/profile"

export default function Layout({children}) {
  return (
    <div className="mt-5 grid grid-cols-3 sm:grid-cols-6 gap-2">
      <div className="flex flex-col col-start-2 col-span-1">
        <Profile />
        <Link to="/"><span className="mt-2 mb-2 text-sm font-bold text-gray-400">eduardo</span></Link>
      </div>
      <div className="border-b col-span-3 sm:col-span-6" />
      <div className="px-2 sm:px-0 col-start-1 col-span-3 sm:col-start-2 sm:col-span-4">
        {children}
      </div>
      <div className="border-b col-span-3 sm:col-span-6" />
      <div className="flex flex-col items-center py-4 text-sm font-bold text-gray-600 sm:flex-row col-start-2 col-span-1">
        <a className="pr-2 hover:bg-gray-50 hover:text-blue-400" href="https://gurgel.me">About</a>
        <a className="pr-2 hover:bg-gray-50 hover:text-blue-400" href="https://github.com/edgurgel">GitHub</a>
        <a className="pr-2 hover:bg-gray-50 hover:text-blue-400" href="https://twitter.com/edgurjas">Twitter</a>
      </div>
    </div>
  )
}
