import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function Profile() {
  return (
    <StaticQuery
      query={graphql`
        query image {
          image: file(base: { eq: "eduardo.jpeg" }) {
            publicURL
          }
        }
      `}
      render={data => (
        <>
          <img alt="Eduardo" src={data.image.publicURL} className="w-32 h-32"/>
        </>
      )}
    />
  )
}
